import { reactive } from 'vue';

export default {
  install(app) {
    const errorBag = reactive({});
    const successMsgBag = reactive({});

    function transformValidationErrors(response, handlerType = 'axios') {
      if (handlerType === 'axios') {
        if (response.data.errors) {
          for (let key in response.data.errors) {
            errorBag[key] = response.data.errors[key][0];
          }
        }
        if (response.data.error) {
          errorBag['error'] = response.data.error;
        }
      }
    }

    function resetErrorBag() {
      Object.keys(errorBag).forEach(key => {
        delete errorBag[key];
      });
    }

    function transformSuccessMsg(response, handlerType = 'axios') {
      if (handlerType === 'axios') {
        if (response.data.status) {
          successMsgBag['status'] = response.data.status;
        }
      }
    }

    function resetSuccessMsgBag() {
      Object.keys(successMsgBag).forEach(key => {
        delete successMsgBag[key];
      });
    }

    app.config.globalProperties.$customError = {
      errorBag,
      successMsgBag,
      transformValidationErrors,
      resetErrorBag,
      transformSuccessMsg,
      resetSuccessMsgBag
    };

    app.provide('customError', {
      errorBag,
      successMsgBag,
      transformValidationErrors,
      resetErrorBag,
      transformSuccessMsg,
      resetSuccessMsgBag
    });
  }
};
