<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12">
        <vnud-card card-body-classes="table-full-width" no-footer-line>
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h4 class="card-title">Contacts Groups</h4>
                <div>{{ 'Found ' + contactGroup.length + ' contacts Groups' }}</div>
              </div>
              <div class="d-flex align-items-center">
                <fg-input class="">
                  <el-input type="search" class="input-with-icon mr-2" clearable style="width: 200px"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                    <template #prefix>
                      <el-icon class="el-input__icon">
                        <search />
                      </el-icon>
                    </template>
                  </el-input>
                </fg-input>
              </div>
            </div>
          </template>
          <el-table stripe style="width: 100%" :data="queriedData">
            <el-table-column min-width="190" prop="name" label="Name" />
            <el-table-column min-width="250" prop="email" label="Email Address" />
            <!-- <el-table-column min-width="250" prop="description" label="Description" /> -->
            <el-table-column min-width="150" header-align="right" label="Actions">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="editContactsGroup(row.id)">
                      <i class="fa-solid fa-pen-to-square text-white"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="deleteContactsGroup(row.id)">
                      <i class="fa-solid fa-rectangle-xmark text-white"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <template v-slot:footer>
            <n-pagination class="pagination-no-border" :total="contactGroup.length" :perPage="perPage"
              v-model="contactPage" />
            <div class="d-flex justify-content-center contact-footer justify-content-sm-between flex-wrap">
              <div class="d-flex align-items-center">
                <span class="mr-2">Groups per page: </span>
                <el-select class="per-page-selector" placeholder="Contacts per page" v-model="perPage">
                  <el-option v-for="option in perPageOptions" :value="option" :label="option" :key="option" />
                </el-select>
              </div>
              <el-tooltip content="New Contacts Group" :open-delay="300" placement="top">
                <n-button type="primary" round icon="" size="lg" style="float: right;" @click="addContactsGroup">
                  <i class="now-ui-icons ui-1_simple-add text-white"></i>
                </n-button>
              </el-tooltip>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPost } from '@/utils/api'
import { Pagination as NPagination, VnudCard } from '@/components/index.js'
import { Search } from '@element-plus/icons-vue'
export default {
  components: {
    VnudCard,
    NPagination,
    Search,
  },
  data() {
    return {
      contactGroup: [],
      contactPage: 1,
      perPage: 30,
      perPageOptions: [5, 10, 30, 50],
      searchQuery: "",
      propsToSearch: ["name"],
    }
  },
  async mounted() {
    this.contactGroup = await apiGet('/contactGroup')
  },
  methods: {   
    // Navigate to the add contact page
    addContactsGroup() {
      this.$router.push('/contacts-groups/new')
    },
    // Navigate to the edit contact page
    editContactsGroup(id) {
      this.$router.push('/contacts-groups/' + id + '/edit')
    },
    // Delete the contact with confirmation
    async deleteContactsGroup(id) {
      const deleteContactsGroup = await this.$swal({
        title: 'Delete Contacts Group?',
        text: 'Are you sure you would like to delete this contacts Group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'Yes, Delete Contacts Group!',
        cancelButtonText: 'Keep Contact',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (deleteContactsGroup.value) {
        await apiPost('/contactGroup/' + id + '/delete')
        // Reload the current page to refresh the contacts list
        window.location.reload()
      }
    },
  },
  computed: {
    // Get the contacts for the current page
    pagedContactsGroups() {
      const start = (this.contactPage - 1) * this.perPage
      const end = start + this.perPage
      return this.contactGroup.slice(start, end)
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedContactsGroups;
      }
      let result = this.contactGroup.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.perPage * (this.contactPage - 1);
    },
    total() {
      return this.contactGroup.length > 0
        ? this.contactGroup.length
        : this.contactGroup.length;
    },
  },
}
</script>

<style>
.google-contact-modal .per-page-selector,
.contact-footer  .per-page-selector{
  width: 100px;
}

.input-with-icon.el-input--prefix .el-input__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}

.input-with-icon .el-input__wrapper {
  border-radius: 50px !important;
}

.input-with-icon .el-input__inner:focus {
  border: 0 !important;
}

.google-contact-modal .close {
  color: rgb(44, 44, 44) !important;
  opacity: 1;
}

.google-contact-modal .modal-content .modal-header {
  justify-content: space-between;
}
</style>
