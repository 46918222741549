<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart" description="These are the invites you've sent" no-footer-line>
          <template v-slot:header>
            <h2 class="card-title">
              Invites Sent
            </h2>
          </template>
          <div role="subheader">
            {{ numberOfInvites }} invites
          </div>
          <br>
          <table class="table">
            <thead>
              <tr>
                <th>Contact</th>
                <th>Event</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invite in pagedInvites" :key="invite.id">
                <td>{{ invite.user ? (invite.user.fullname ? invite.user.fullname : invite.user.name) :
                  invite.contact.name }}
                </td>
                <td>{{ invite.event.name }}</td>
                <td class="datesent">{{ invitedAt(invite.createdAt) }}</td>
                <td>
                  <el-button type="info" size="small" @click="goTo(invite.id)">
                    <i class="now-ui-icons ui-1_send text-white"></i>
                  </el-button>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <pagination :total="numberOfInvites" :perPage="perPage" v-model:currentPage="invitePage" />
          Invites per page:
          <el-select class="per-page-selector" placeholder="Invites per page" v-model="perPage">
            <el-option v-for="option in perPageOptions" :value="option" :label="option" :key="option">
            </el-option>
          </el-select>
          <br>
        </vnud-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { apiGet } from '@/utils/api'
import {
  Pagination,
  VnudCard,
} from '@/components/index.js'
import {  ElSelect, ElOption, ElButton } from 'element-plus'

export default {
  components: {
    VnudCard,
    Pagination,
    ElSelect,
    ElOption,
    ElButton
  },
  data() {
    return {
      invites: [],
      invitePage: 1,
      perPage: 30,
      perPageOptions: [5, 10, 30, 50]
    }
  },
  async mounted() {
    // Fetch invites once component is mounted
    this.invites = await apiGet('/attendee/sent')
  },
  computed: {
    pagedInvites() {
      // Pagination logic to show a slice of the invite list
      const start = (this.invitePage - 1) * this.perPage
      const end = start + this.perPage
      return this.invites.slice(start, end)
    },
    numberOfInvites() {
      // Total number of invites
      return this.invites.length
    }
  },
  methods: {
    invitedAt(createdAt) {
      // Format the invitation date using moment.js
      return moment(createdAt).format('MMM DD')
    },
    goTo(attendeeId) {
      // Navigate to a specific invite using attendee ID
      this.$router.push('/invites/' + attendeeId)
    }
  }
}
</script>

<style scoped>
.per-page-selector {
  width: 100px;
}

.datesent {
  width: 75px;
}
</style>
