<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Edit Contact">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">Edit Contact</h4>
              <div content="Go Back" :open-delay="300" placement="top">
                <n-button type="info" size="sm" @click="Contacts()">
                  <img class="action-button-icon" style="cursor:pointer"
                    src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                </n-button>
              </div>
            </div>
          </template>
          <form method="#" action="#" @submit.prevent>
            <div>
              <fg-input v-model="contact.name" label="Name" placeholder="Enter the contact's name" />
              <fg-input v-model="contact.email" label="Email" placeholder="Enter the contact's email" />
              <fg-input v-model="contact.phone" label="Phone" placeholder="Enter the contact's phone" />
              <n-button @click="saveContact" class="text-white">Save Contact</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal :show="showAlert" class="modal-primary" :show-close="false">
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>

import {
  Modal,
  VnudCard,
} from '@/components/index.js'

import { apiPut, apiGet } from '@/utils/api'

export default {
  components: {
    Modal,
    VnudCard,
  },
  data() {
    return {
      id: '',
      contact: {
        name: '',
        email: '',
        phone: '',
      },
      showAlert: false
    }
  },
  async mounted() {
    this.id = this.$route.params.contactId
    this.contact = await apiGet('/contact/' + this.id)
  },
  methods: {
    saveContact: async function () {
      if (!this.contact.name || !this.contact.email) {
        this.alertMessage = 'Please enter all required Contact data'
        this.showAlert = true
      } else if (!this.validEmail(this.contact.email)) {
        this.alertMessage = 'Please enter a valid email address'
        this.showAlert = true
      } else {
        try {
          await apiPut('/contact/' + this.id, this.contact)
          this.$router.push('/contacts/' + this.id)
        } catch (e) {
          this.alertMessage = 'Error saving contact, please try again later'
          this.showAlert = true
        }
      }
    },
    Contacts: function () {
      this.$router.push('/contacts/')
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }

  }
}
</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>
