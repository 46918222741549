<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart" subTitle="" description="" no-footer-line>
          <div>
            <h2>{{ event.name }}</h2>
            <strong>Description:</strong> {{ event.description }}
          </div>
          <br>
          <div>
            <div>
              <strong>For:</strong> {{ contact.name }}
            </div>
            <div>
              <strong>Role:</strong> {{ invite.role }}
            </div>
            <div>
              <strong>Status:</strong> {{ invite.status }}
            </div>
            <div>
              <strong>Sent By:</strong> {{ host.fullname ? host.fullname : host.email }}
            </div>
            <div>
              <strong>Sent At:</strong> {{ formattedCreatedAt }}
            </div>
            <br>
            <div>
              <strong>Location:</strong> {{ location.text }}
            </div>
            <div>
              <strong>Start:</strong> {{ formatDate(event.start) }}
              <br>
              <span v-if="event.end"><strong>End:</strong> {{ formatDate(event.end) }}</span>
            </div>
            <div>
              <button @click="loadEvent()" type="button" class="btn btn-sm">
                <span class="btn-label">
                  <i class="now-ui-icons location_map-big"></i>
                </span>
                Map / Directions
              </button>
            </div>
          </div>
          <div v-if="viewEvent">
            <events-view-single :embedded="true" :event="event" :locationText="location.text" ref="single" />
          </div>
        </vnud-card>
        <vnud-card v-if="isGuest" class="card-chart" subTitle="Invite Status" description="" no-footer-line>
          <div style="text-align: center;">
            <button @click="status('confirmed')" type="button" class="btn btn-sm"
              :class="{ 'btn-success': invite.status === 'confirmed' }">
              <span class="btn-label">
                <i class="now-ui-icons ui-1_check"></i>
              </span>
              Yes
            </button>

            <button @click="status('maybe')" type="button" class="btn btn-sm"
              :class="{ 'btn-warning': invite.status === 'maybe' }">
              <span class="btn-label">
                <i class="now-ui-icons tech_watch-time"></i>
              </span>
              Maybe
            </button>

            <button @click="status('declined')" type="button" class="btn btn-sm"
              :class="{ 'btn-danger': invite.status === 'declined' }">
              <span class="btn-label">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </span>
              No
            </button>
          </div>
        </vnud-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// import { Table, TableColumn } from 'element-plus'
import { apiPut, apiGet } from '@/utils/api'
import { getApiUserFromLocalStorage } from '@/utils/auth'
import EventsViewSingle from '../Events/EventsViewSingle.vue'
import {
  // StatsCard,
  VnudCard,
  // Table as NTable,
  // Modal,
  // Collapse,
  // CollapseItem
} from '@/components/index.js'

export default {
  components: {
    VnudCard,
    // NTable,
    // StatsCard,
    // Modal,
    // Collapse,
    // CollapseItem,
    EventsViewSingle
  },
  data() {
    return {
      user: { id: 0, name: '', email: '' },
      contact: { email: '', name: '' },
      host: { name: '' },
      invite: {
        role: '',
        status: '',
        eventId: '',
        createdAt: '2000-01-01 00:00:00'
      },
      event: { id: 0, name: '' },
      viewEvent: false,
      loaded: false,
      location: { text: '' }
    }
  },
  computed: {
    isGuest() {
      return this.user ? getApiUserFromLocalStorage().id === this.user.id : false
    },
    formattedCreatedAt() {
      return moment(this.invite.createdAt).format('MMM DD, YYYY hh:mm a')
    }
  },
  async created() {
    this.invite = await apiGet('/attendee/' + this.$route.params.inviteId)
    if (this.invite.userId) {
      this.user = await apiGet('/user/' + this.invite.userId)
    }
    this.event = await apiGet('/event/' + this.invite.eventId)
    this.location = await apiGet('/location/' + this.event.locationId)
    this.host = await apiGet('/user/' + this.invite.invitedBy)
    this.contact = await apiGet('/contact/' + this.invite.contactId)
  },
  methods: {
    formatDate(date) {
      return moment.utc(date).format('ddd, MMM D, YYYY @ h:mm a')
    },
    async status(val) {
      this.invite = await apiPut('/attendee/' + this.invite.id, { status: val })
    },
    loadEvent() {
      this.viewEvent = !this.viewEvent
      if (!this.loaded) {
        this.$nextTick(function () {
          this.$refs.single.init()
          this.loaded = true
        })
      }
    },
    closeEvent() {
      this.viewEvent = false
    }
  }
}
</script>

<style scoped>
.btn-full {
  width: 250px;
}

.modal-content .modal-header button {
  top: 30px;
  outline: 0;
}

.modal-header .close {
  padding-top: 0px;
  margin: -20px -20px -20px auto;
}
</style>
