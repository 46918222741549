<template>
    <div class="content content-page mx-auto">
        <div class="row">
            <div class="col-md-12 mx-auto logo-center">
                <a href="/"> <img src="@/assets/img/iev-logo.png" class="content-logo" alt="InstaEvent"></a>
                <h1 class="home-box">InstaEvent</h1>
            </div>
            <div class="col-lg-12 ml-auto">

                <div class="description">
                    <h1 class="info-title">Why InstaEvent?</h1>
                    <p>This is the first release (MVP) of InstaEvent which only supports the creation of private events.
                        Features in this release are limited.</p>
                    <ul class="pl-8">
                        <li>InstaEvent allows you to quickly and easily organize private events with family and friends.
                        </li>
                        <br>
                        <li>InstaEvent default privacy settings keep all media and communications private and secure
                            among attendees. </li>
                        <br>
                        <li>InstaEvent is perfect for weddings, birthday parties, school events or even a weekend in Las
                            Vegas.</li>
                        <br>
                        <li>InstaEvent works by sending event attendees an email message with details about the event.
                            Invited attendees can RSVP by a push a button. </li>
                    </ul>
                </div>
            </div>
            </div>
    </div>
</template>

<style>
.row {
    margin-top: -50px;
}

.content-logo {
    width: 85px;
}

.logo-center {
    text-align: center;
}
.info-title {
    font-size: 18px
}

</style>
