<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-alert v-if="hasErrors" :type="type">
    <div v-for="(message, field) in errorBag" :key="'message_' + field">
      <div>{{ field }}: {{ message }}</div>
    </div>
  </v-alert>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  errorBag: { type: Object, default: () => ({}) },
  type: { type: String, default: "error" },
});

const hasErrors = computed(() => {
  return Object.keys(props.errorBag).length > 0;
});
</script>
