<template>
    <div class="content content-page mx-auto">
        <div class="row">
            <div class="col-md-12 mx-auto logo-center">
                <a href="/"> <img src="@/assets/img/iev-logo.png" class="content-logo" alt="InstaEvent"></a>
                <h1 class="home-box">InstaEvent</h1>
            </div>
            <div class="col-lg-12 ml-auto">

                <div class="description">
                    <h1 class="info-title">About Us</h1>
                    <p>InstaEvent consists of dedicated and passionate people who are committed to family, friends and
                        hard work.</p>
                    <p>As with all entrepreneurs, we have an enthusiasm for building technology-driven applications and
                        websites that change the way we communicate with others. Our drive for success comes from our
                        experience of success and failures and overcoming obstacles that most people would turn away
                        from.</p>
                    <p>With the decline of our Facebook, Snap and Twitter friends, we realized using text messaging was
                        the only way of getting seven people out on a Saturday night. After becoming overwhelmed with
                        messages back and forth from what time to leave to where to eat, there had to be a better way.
                    </p>
                    <p>InstaEvent is just getting started so please email us with your ideas and feedback on how we can
                        make it better. </p>
                    <br>
                    <p>Team InstaEvent<br><a href="mailto:info@instaevent.com">info@InstaEvent.com</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.row {
    margin-top: -50px;
}

.content-logo {
    width: 85px;
}

.logo-center {
    text-align: center;
}
</style>
