<template>
  <transition-slide>
    <div class="modal google-contact-modal fade" @click.self="closeModal"
      :class="[{ 'show d-block': show }, { 'd-none': !show }, { 'modal-mini': type === 'mini' }]" v-show="show"
      tabindex="-1" role="dialog" :aria-hidden="!show">

      <div class="modal-dialog" :class="[{ 'modal-notice': type === 'notice' }, modalClasses]">
        <div class="modal-content">

          <div class="modal-header" :class="headerClasses">
            <!-- Update named slots using Vue 3's slot API -->
            <slot name="header" class="float-right"></slot>
            <slot name="close-button">
              <button type="button" v-if="showClose" @click="closeModal" class="close" data-dismiss="modal"
                :aria-hidden="!show">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>

    </div>
  </transition-slide>
</template>

<script>
export default {
  name: 'vnud-modal',
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.includes(value);
      }
    },
    modalClasses: [Object, String],
    headerClasses: [Object, String],
    bodyClasses: [Object, String],
    footerClasses: [Object, String],
    animationDuration: {
      type: Number,
      default: 500
    }
  },
  emits: ['update:show', 'close'], // Define emits in Vue 3
  methods: {
    // Method to close the modal and emit necessary events
    closeModal() {
      this.$emit('update:show', false); // Use `update:modelValue` or similar event in Vue 3 for syncing v-model
      this.$emit('close');
    }
  },
  watch: {
    show(val) {
      // Watcher to handle the body class when modal is shown or hidden
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    }
  }
};
</script>

<style scoped>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
