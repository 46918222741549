<template>
	<div>
		<table class="table inviteGuest mb-0">
			<thead>
				<tr>
					<th>
					</th>
					<th>Guest Name</th>
					<th>Invite Status</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(record, index) in data" :key="index">
					<td style="min-width: 30px">
						<img v-if="record.user && record.user.picture" :src="record.user.picture" />
						<vue-gravatar v-else :email="(record.user && record.user.email) ? record.user.email : record.contact.email"
							style="width:40px;height:40px" default="retro" />
					</td>
					<td>{{ record.user ? (record.user.fullname ? record.user.fullname : record.user.name) :
						record.contact.name }}</td>
					<td>{{ record.attendee.status }}</td>
					<td>
						<button class="btn btn-sm" @click="removeGuest(record.attendee.id)"
							v-if="isHost && eventInFuture && (((record.user && record.user.email) ? record.user.email : record.contact.email) !== user.email)">
							<i class="now-ui-icons ui-1_simple-remove"></i>
						</button>
					</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td>
						<n-button v-if="isHost && eventInFuture" class="floatright" type="primary" round="" icon="" size="lg"
							@click="addGuest()">
							<i class="now-ui-icons ui-1_simple-add text-white"></i>
						</n-button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import moment from 'moment'
import { apiPost } from '@/utils/api'
import { getUserFromLocalStorage } from '@/utils/auth'
export default {
	props: ['data', 'event'],
	data() {
		return {
			isHost: false,
			user: getUserFromLocalStorage()
		}
	},
	watch: {
		event: async function (to) {
			this.isHost = (await apiPost('/attendee/host/' + to.id)).isHost
		}
	},
	methods: {
		addGuest: function () {
			this.$router.push('/invites/new?eventId=' + this.event.id)
		},
		removeGuest: async function (id) {
			let deleteAttendee = await this.$swal({
				title: 'Delete Attendee?',
				text: `Are you sure you would like to delete this attendee?`,
				type: 'question',
				showCancelButton: true,
				confirmButtonClass: 'btn btn-danger btn-fill',
				cancelButtonClass: 'btn  btn-success btn-fill',
				confirmButtonText: 'Yes, Delete Attendee!',
				cancelButtonText: 'Keep Attendee',
				buttonsStyling: false
			})
			if (deleteAttendee.value) {
				await apiPost('/attendee/' + id + '/delete')
				this.$emit('refresh')
			}
		}
	},
	computed: {
		eventInFuture: function () {
			return this.selDate > this.nowDate
		},
		selDate: function () {
			return new Date(moment.utc(this.event.start).format('YYYY-MM-DD HH:mm:ss'))
		},
		nowDate: function () {
			return new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
		}
	},
}
</script>
<style scoped>
img {
	width: 40px;
	height: 40px;
	overflow: hidden;
	border-radius: 50%;
	margin-bottom: 15px;
}

.floatright {
	float: right;
}

.inviteGuest td,
th {
	vertical-align: baseline;
}
</style>