import axios from 'axios'
import { getTokenFromLocalStorage, getTokenFromCookie } from '@/utils/auth'

const createAxios = () => {
  let token = getTokenFromLocalStorage();
  return axios.create({
    baseURL: import.meta.env.VITE_APP_API_BASE_URL,
    headers: { Authorization: 'Bearer ' + token }
  });
}

const createMultipartAxios = () => {
  const token = getTokenFromLocalStorage(); 
  return axios.create({
    baseURL: import.meta.env.VITE_APP_API_BASE_URL,
    headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }
  });
}

/*
export const api = () =>
  typeof window.ievAxios === 'undefined' ? (window.ievAxios = createAxios()) : window.ievAxios
*/

export const api = () =>{
  return createAxios();
}

export const apiGet = async (route) => {
  try {
    const response = await api().get(route);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export const apiPost = async (route, data) => {
  try {
    const response = await api().post(route, data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export const apiPut = async (route, data) => {
  return (await api().put(route, data)).data;
}

export const apiPostFile = async (route, data) => {
  try {
    const response = await createMultipartAxios().post(route, data);
    return response.data;
  } catch (err) {
    throw err;
  }
}


