<template>
  <footer class="footer dashboard-footer">
    <div class="container-fluid d-flex flex-wrap justify-content-end">
      <nav>
        <div class="copyright"><a href="/privacy-policy">Privacy Policy</a> - <a href="/terms-and-conditions">Terms and
            Services</a> -<a href="/copyright-policy"> &copy; {{ year }}</a></div>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}

</script>
<style></style>
