<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Create Location">
          <template v-slot:header>
            <h4 class="card-title">
              Create Location
            </h4>
          </template>
          <form method="#" action="#" @submit.prevent>
            <div>
              <div class="form-group has-label">
                <label>Location</label>
                <v-autocomplete v-model="location" variant="solo"  density="compact" :rounded="true" :items="filteredOptions"
                  placeholder="Where is the event?" class="custom-autocomplete" v-model:search="searchQuery" :loading="loading"
                  no-data-text="No results found" hide-no-data @update:search="fetchData" @blur="handleBlur"
                  @focus="handleFocus"></v-autocomplete>
              </div>
              <n-button @click="createLocation" class="text-white">Submit</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>Please enter all required Location data</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>

import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import { apiPost } from '@/utils/api.js'
export default {
  components: {
    Modal,
    VnudCard,
  },
  data() {
    return {
      location: '',
      showAlert: false,
      filteredOptions: [], // Holds the API-fetched options
      loading: false, // Loading state during API call
      searchQuery: '', // Holds user input for search
    }
  },
  methods: {
    createLocation: async function () {
      if (!this.location) {
        this.showAlert = true
        return
      }
      let created = await apiPost('/location', { text: this.location })
      this.$router.push('/locations/' + created.id)
    },
    async fetchData(input) {
      if (!input) {
        this.options = [];
        return;
      }
      this.loading = true;
      try {
        let response = await apiPost('/location/search', { 'text': input })
        this.filteredOptions = response
      } catch (error) {
        this.filteredOptions = [];
      } finally {
        this.loading = false;
      }
    },
    handleBlur() {
      // If the input is not found in the options, use the typed input as the selected value
      if (!this.filteredOptions.includes(this.location)) {
        this.location = this.searchQuery;
      }

      // Keep the placeholder visible by not clearing the searchQuery on blur unless a value is selected
      if (!this.location) {
        this.searchQuery = ''; // Resets to show placeholder if no option was selected
      }

      if (this.location === '' && this.searchQuery === '') {
        this.searchQuery = null;
        this.location = null;
      }
    },
    handleFocus() {
      if (!this.location) {
        this.searchQuery = ''; // Prepare input for new search on focus
      }
    },
  }
}
</script>
<style></style>
