<template>
  <div>
    <p>Logging Out...</p>
    <div id="auth0-lock" />
  </div>
</template>

<script>
import { unsetToken } from '@/utils/auth'
//import { logout } from '@/utils/lock'

export default {
  name: 'logout',
  mounted () {
    unsetToken()
   // logout("auth0-lock", 'logout')
  }
}
</script>