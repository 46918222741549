<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card card-body-classes="table-full-width" :subTitle="'found ' + events.length"
          description="These are the events you're part of" no-footer-line>
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">Hosting</h4>
              <n-button class="text-white" @click="showPast = !showPast">
                {{ showPast ? 'Upcoming Events' : 'Past Events' }}
              </n-button>
            </div>
          </template>
          <el-table stripe style="width: 100%" :data="events.hosting">
            <el-table-column min-width="170" prop="name" label="Name">
            </el-table-column>
            <el-table-column min-width="110" prop="startsAt" label="Starts">
            </el-table-column>
            <el-table-column min-width="110" label="View" header-align="right">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <el-tooltip content="View Event" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="goTo(row.id)">
                      <i class="now-ui-icons ui-1_calendar-60"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <h4 class="card-title pl-4">Attending</h4>
          <el-table stripe style="width: 100%" :data="events.attending">
            <el-table-column min-width="170" prop="name" label="Name">
            </el-table-column>
            <el-table-column min-width="110" prop="startsAt" label="Starts">
            </el-table-column>
            <el-table-column min-width="110" label="View" header-align="right">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="goTo(row.id)">
                      <i class="now-ui-icons ui-1_calendar-60"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <template v-slot:footer>
            <div class="d-flex justify-content-end">
              <el-tooltip content="New Event" :open-delay="300" placement="top">
              <n-button type="primary" round="" icon="" size="lg" @click="newEvent()">
                <i class="now-ui-icons ui-1_simple-add text-white"></i>
              </n-button>
            </el-tooltip>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet } from '@/utils/api.js'

import {
  VnudCard,
} from '@/components/index.js'

export default {
  components: {
    VnudCard,
  },
  data() {
    return {
      events: [],
      invites: [],
      showPast: false,
    }
  },
  watch: {
    showPast: async function (to) {
      this.events = await apiGet('/event' + ((to) ? '?mode=past' : ''))
    }
  },
  async mounted() {
    this.events = await apiGet('/event')
    this.invites = await apiGet('/attendee/received')
  },
  methods: {
    goTo: function (id) {
      this.$router.push('/events/' + id)
    },
    goToInvite: function (id) {
      this.$router.push('/invites/' + id)
    },
    newEvent: function () {
      this.$router.push('/events/new')
    }
  }
}
</script>
<style></style>
