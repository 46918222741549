// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import './polyfills'
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin'
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SidebarPlugin from './components/SidebarPlugin'
// Tabs plugin. Used on Panels page.

// Asset imports
import "bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap/scss/bootstrap.scss'
import './assets/css/demo.scss'
// Library auto imports
import 'es6-promise/auto'
import "./assets/scss/now-ui-dashboard.scss";
import 'element-plus/dist/index.css'
export default {
  install(app) {
    app.use(GlobalComponents)
    app.use(GlobalDirectives)
    app.use(SidebarPlugin)
    app.use(Notifications)

  }
}
