<template>
  <div class="card hover-button vue-avatar-cropper-demo text-center" v-if="avatarVisible">
      <avatar-cropper v-model="showCropper" @uploading="handleUploading" @uploaded="handleUploaded"
      @completed="handleCompleted" @error="handlerError" trigger="#pick-avatar"
      :labels="{ submit: 'Upload', cancel: 'Cancel' }" :upload-handler="uploadHandler" />
    <div class="card-body">
      <img :src="avatar" class="card-img avatar" style="width:80px;height:80px" />
      <div class="card-img-overlay">
        <button class="btn btn-primary btn-sm" @click="showCropper = true">Select an new image</button>
      </div>
      <h5 class="card-title mb-0">{{ fullname }}</h5>
      {{ email }}
    </div>
    <div class="card-footer text-muted" v-html="message"></div>
  </div>
  <div v-else>
    <div>
      Loading profile ...
    </div>
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper"
import { getTokenFromLocalStorage } from '@/utils/auth'
import { md5 } from 'js-md5';
export default {
  components: { AvatarCropper },
  props: ['user'],
  data() {
    return {
      message: '(click to change)',
      avatar: '',
      avatarVisible: false,
      fullname: '',
      email: '',
      showCropper: false,
    }
  },
  mounted() {

  },
  computed: {
    uploadURL: () => import.meta.env.VITE_APP_API_BASE_URL + '/media/avatar',
    token: () => getTokenFromLocalStorage(),
  },
  watch: {
    'user.picture': function (to) {
      this.avatar = to
      this.avatarVisible = true
    },
    'user.email': function (to) {
      this.email = to
      this.avatar = this.avatar ? this.avatar : 'https://www.gravatar.com/avatar/' + md5(this.email)
      this.avatarVisible = true
    },
    'user.fullname': function (to) {
      this.fullname = to
    }
  },
  methods: {
    uploadHandler(cropper) {
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'avatar.png');

        try {
          const response = await fetch(this.uploadURL, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + this.token
            },
            body: formData
          });

          const result = await response.json();
          if (response.ok) {
            this.handleUploaded(result);
          } else {
            this.handlerError('Upload failed', 'upload', response);
          }
        } catch (error) {
          this.handlerError('Error uploading image', 'upload', error);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    handleUploading(form, xhr) {
      this.message = "Uploading..."
    },
    handleUploaded(response) {
      if (response.status == "success") {
        this.avatar = response.url
        this.message = "User avatar updated."
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleCompleted(response, form, xhr) {
      this.message = "Upload completed."
      window.location = window.location
    },
    // eslint-disable-next-line no-unused-vars
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong..."
    }
  }
};
</script>

<style>
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}

.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}

.card-img-overlay {
  display: none;
  transition: all 0.5s;
}

.hover-button:hover .card-img-overlay {
  display: block;
}
</style>
