<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart" title="" subTitle="" description="" no-footer-line>
          <template v-slot:header>
            <div>
              <h4 class="card-title">Location</h4>
            </div>
          </template>
          <div>
            Address: {{ locationText }}
          </div>
          <br>
          <div class="col-md-12 p-0">
            <vnud-card class="vnud-card-map mb-1">
              <div id="satelliteMap" class="map map-big"></div>
            </vnud-card>
          </div>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '@/utils/api.js'
import { Loader } from 'google-maps'
const loader = new Loader(import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY);

import { VnudCard } from "@/components/index.js"

export default {
  components: {
    VnudCard,
  },
  data() {
    return {
      locationText: ''
    }
  },
  mounted() {
    this.getLocation(this.$route.params.locationId)
      .then(async (responseText) => {
        this.locationText = responseText

        await loader.load()
        this.initMap(responseText)
      })
  },
  methods: {
    getLocation: function (locationId) {
      return api()
        .get('/location/' + locationId)
        .then(response => response.data.text)

    },
    initMap(location) {
      const mapOptions = {
        zoom: 13,
        scrollwheel: false,
      }
      const resultsMap = new google.maps.Map(document.getElementById('satelliteMap'), mapOptions)
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': location }, (results, status) => {
        if (status === 'OK') {
          resultsMap.setCenter(results[0].geometry.location);
          new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location,
            title: 'Event Location'
          });
        }
      })
    },
  }
}
</script>
