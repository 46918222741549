<template>
  <div>
    <div :class="[{ 'col-md-4': vertical && !tabNavWrapperClasses }, { 'col-12': centered && !tabNavWrapperClasses }, tabNavWrapperClasses]">
      <ul class="nav nav-pills" role="tablist" :class="[`nav-pills-${type}`, { 'nav-pills-icons': square }, { 'flex-column': vertical }, { 'justify-content-center': centered }, tabNavClasses]">
        <li v-for="tab in tabs" :key="tab.id" class="nav-item" data-toggle="tab" role="tablist" aria-expanded="true">
          <a data-toggle="tab" role="tablist" @click.prevent="activateTab(tab)" :aria-expanded="tab.active" class="nav-link" :class="{ active: tab.active }">
            <tab-item-content :tab="tab"></tab-item-content>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content" :class="[{ 'tab-space': !vertical }, { 'col-md-8': vertical && !tabContentClasses }, tabContentClasses]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { h } from 'vue'
export default {
  name: 'vnud-tabs',
  components: {
    TabItemContent: {
      props: ['tab'],
      render() {
        return h('div', [this.tab.$slots?.label?.() || this.tab.label])
      }
    }
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    }
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.includes(value)
      }
    },
    value: {
      type: String,
      default: '' // v-model equivalent
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      default: ''
    },
    tabNavClasses: {
      type: [String, Object],
      default: ''
    },
    tabContentClasses: {
      type: [String, Object],
      default: ''
    },
    vertical: Boolean,
    square: Boolean,
    centered: Boolean
  },
  data() {
    return {
      tabs: [] // To keep track of all tab components
    }
  },
  methods: {
    // Finds and activates the tab based on the label
    findAndActivateTab(label) {
      const tabToActivate = this.tabs.find(tab => tab.label === label)
      if (tabToActivate) {
        this.activateTab(tabToActivate)
      }
    },
    // Activates the tab passed in as a parameter
    activateTab(tab) {
      this.deactivateTabs()
      tab.active = true
      this.$emit('update:modelValue', tab.label)
    },
    // Deactivates all tabs
    deactivateTabs() {
      this.tabs.forEach(tab => {
        tab.active = false
      })
    },
    // Adds a new tab to the tabs array
    addTab(tab) {
      const index = this.tabs.length

      // If this is the first tab or matches the v-model value, activate it
      if (index === 0 || this.value === tab.label) {
        tab.active = true
      }
      this.tabs.push(tab)
    },
    removeTab(tab) {
      const index = this.tabs.indexOf(tab)
      if (index !== -1) {
        this.tabs.splice(index, 1)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.findAndActivateTab(this.value)
      }
    })
  },
  watch: {
    value(newVal) {
      this.findAndActivateTab(newVal)
    }
  }
}
</script>
