<template>
  <div>
    <div v-if="!mapOnly">
      <div v-if="isHost && eventInFuture" style="float: right">
        <img style="cursor:pointer" @click="goEdit" width="20"
          src="@/assets/img/glyphicons-halflings-74-square-edit.svg" />
      </div>
      <h4 class="card-title">{{ event.name }}</h4>
      <div>
        <strong>Hosted by:</strong> {{ host.fullname ? host.fullname : host.name }}
      </div>
      <div>
        <strong>Description:</strong> {{ event.description }}
      </div>
      <div>
        <strong>Start:</strong> {{ formatDate(event.start) }}
        <span class="d-block" v-if="event.end"><strong>End:</strong> {{ formatDate(event.end) }}</span>
      </div>
      <div v-if="event.type == 'public'">
        <strong>Type: Public Event</strong> <i class="now-ui-icons ui-1_lock-circle-open" :title="event.type"></i>
      </div>
      <div v-else>
        <strong>Type:</strong> Private Event <i class="now-ui-icons objects_key-25" :title="event.type"></i>
      </div>
      <div>
        <strong>Location:</strong> {{ locationText }}
      </div>
    </div>
    <div>
      <n-button @click="openInGoogleMaps" class="text-white">Open in Maps</n-button>
      <div id="satelliteMap" class="map map-big w-100"></div>
    </div>
    <vnud-card class="card-chart" subTitle="Invite Status" description="" no-footer-line>
      <div style="text-align: center;">
        <h5>Your attendance</h5>
        <button @click="status('confirmed')" type="button" class="btn btn-sm text-white"
          :class="{ 'btn-success': invite.status === 'confirmed' }">
          <span class="btn-label">
            <i class="now-ui-icons ui-1_check"></i>
          </span>
          Yes
        </button>

        <button @click="status('maybe')" type="button" class="btn btn-sm text-white"
          :class="{ 'btn-warning': invite.status === 'maybe' }">
          <span class="btn-label">
            <i class="now-ui-icons tech_watch-time"></i>
          </span>
          Maybe
        </button>

        <button @click="status('declined')" type="button" class="btn btn-sm text-white"
          :class="{ 'btn-danger': invite.status === 'declined' }">
          <span class="btn-label">
            <i class="now-ui-icons ui-1_simple-remove"></i>
          </span>
          No
        </button>
      </div>
    </vnud-card>
  </div>
</template>

<script>
import { apiGet, apiPut } from '@/utils/api'
import moment from 'moment'
import { Loader } from 'google-maps'
const loader = new Loader(import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY);


export default {
  name: 'EventView',
  props: ['event', 'locationText', 'mapOnly', 'isHost', 'host'],
  data() {
    return {
      googleMap: {},
      eventLocation: {},
      invite: { status: 'invited' },
    }
  },
  watch: {
    locationText() {
      this.loadMap()
    },
    event: async function (to) {
      this.invite = await apiGet('/attendee/user/event/' + to.id)
    }
  },
  computed: {
    mapLink() {
      return 'https://www.google.com/maps/search/?api=1&query=' +
        this.eventLocation.lat().toFixed(7) + ',' +
        this.eventLocation.lng().toFixed(7)
    },
    eventInFuture() {
      return this.selDate > this.nowDate
    },
    selDate() {
      return new Date(moment.utc(this.event.start).format('YYYY-MM-DD HH:mm:ss'))
    },
    nowDate() {
      return new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
    }
  },
  mounted() {
    this.loadMap()
  },
  methods: {
    formatDate(date) {
      return moment.utc(date).format('ddd, MMM D, YYYY @ h:mm a')
    },
    goEdit() {
      this.$router.push('/events/' + this.event.id + '/edit')
    },
    async loadMap() {
      await loader.load()
      this.renderMap()
    },
    renderMap() {
      this.googleMap = new google.maps.Map(
        document.getElementById('satelliteMap'),
        { zoom: 13, scrollwheel: false }
      )
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: this.locationText }, (results, status) => {
        if (status === 'OK') {
          this.eventLocation = results[0].geometry.location
          this.googleMap.setCenter(this.eventLocation)
          new google.maps.Marker({
            map: this.googleMap,
            position: this.eventLocation,
            title: 'Event Location'
          })
        }
      })
    },
    openInGoogleMaps() {
      window.open(this.mapLink)
    },
    async status(inviteStatus) {
      this.invite = await apiPut('/attendee/user/event/' + this.event.id, { status: inviteStatus })
    }
  }
}
</script>
