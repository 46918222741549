<template>
  <div v-if="embedded">
    <event :event="eventData" :locationText="location" :mapOnly="true" :host="host" :isHost="isHost" />
  </div>
  <div v-else class="row">
    <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
      <vnud-card class="front-z">
        <tabs type="primary" v-model="section" :value="section">
          <tab-pane label="event">
            <template v-slot:label>
              <span>
                <i class="now-ui-icons ui-1_calendar-60"></i>
              </span>
            </template>
            <vnud-card class="card-chart" no-footer-line>
              <event :event="eventData" :locationText="location" :host="host" :isHost="isHost" />
            </vnud-card>
          </tab-pane>
          <tab-pane label="attendees">
            <template v-slot:label>
              <span>
                <i class="now-ui-icons users_circle-08"></i>
              </span>
            </template>
            <vnud-card>
              <events-attendee-list :data="attendees" :event="eventData" v-if="eventData" @refresh="refreshAttendees" />
            </vnud-card>
          </tab-pane>
          <tab-pane label="photos">
            <template v-slot:label>
              <span>
                <i class="now-ui-icons design_image"></i>
              </span>
            </template>
            <vnud-card class="mediaUpload">
              <template v-slot:header>
                <h4 class="card-title">
                  Upload Event Media
                </h4>
              </template>
              <div style="margin-top: 20px">
                <events-post-media :event-id="getEventId"/>
              </div>
            </vnud-card>
            <vnud-card v-if="mediaList.length">
              <template v-slot:header>
                <h4 class="card-title">
                  Event Gallery
                </h4>
              </template>
              <events-view-media :mediaList="mediaList" :isHost="isHost" />
            </vnud-card>
          </tab-pane>
        </tabs>
      </vnud-card>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPost } from '@/utils/api.js'
import { VnudCard, TabPane, Tabs } from '@/components/index.js'

import Event from './Event.vue'
import EventsAttendeeList from './EventsAttendeeList.vue'
import EventsViewMedia from './EventsViewMedia.vue'
import EventsPostMedia from './EventsPostMedia.vue'

export default {
  components: {
    Event,
    EventsAttendeeList,
    EventsViewMedia,
    EventsPostMedia,
    VnudCard,
    TabPane,
    Tabs,
  },
  props: ['event', 'embedded', 'locationText'],
  data() {
    return {
      location: '',
      attendees: [],
      mediaList: [],
      section: '',
      eventData: {},
      isHost: false,
      host: { name: '' },
    }
  },
  computed: {
    getEventId: function () {
      return this.embedded ? this.eventData.id : this.$route.params.eventId
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    init: async function () {
      this.section = this.$route.query.section
      if (!this.event) {
        this.eventData = await apiGet('/event/' + this.getEventId)
      } else {
        this.eventData = this.event
      }
      if (!this.locationText) {
        this.location = (await apiGet('/location/' + this.eventData.locationId)).text
      } else {
        this.location = this.locationText
      }
      this.isHost = (await apiPost('/attendee/host/' + this.getEventId)).isHost
      this.host = (await apiGet('/attendee/host/' + this.getEventId)).host
      if (!this.embedded) {
        this.attendees = await apiGet('/attendee/event/' + this.getEventId)
        this.mediaList = (await apiGet('/media/event/' + this.getEventId)) || []
      }
    },
    refreshAttendees: async function () {
      this.attendees = await apiGet('/attendee/event/' + this.getEventId)
    }
  }
}
</script>
<style>
@media screen and (max-width: 450px) {
  .nav-pills .nav-item .nav-link {
    min-width: 18vw !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  .nav-item {
    width: 26vw !important;
  }
}

@media screen and (min-width: 450px) {
  .nav-pills .nav-item .nav-link {
    min-width: 65px;
    padding-left: 11px !important;
    padding-right: 11px !important;
  }
}

.nav-pills .nav-item i {
  line-height: 25px !important;
}

.close {
  color: #fff !important;
}

.prev {
  color: #fff !important;
}

.next {
  color: #fff !important;
}

.mediaUpload {
  text-align: center;
}
</style>
