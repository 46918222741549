<template>
  <div>
    <input type="text" ref="autocompleteInput" v-model="searchText" :placeholder="placeholder" class="form-control" />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'AutoComplete',
  props: {
    options: {
      type: Object,
      default: () => ({
        types: ['geocode'], // Default to geocoding (addresses)
        componentRestrictions: { country: 'us' },
      }),
    },
    placeholder: {
      type: String,
      default: 'Enter a location',
    },
    modelValue: [String],
  },
  emits: ['placeSelected', 'update:modelValue'],
  setup(props, { emit }) {
    const autocompleteInput = ref(null);
    const searchText = ref(props.modelValue || '');
    watch(() => props.modelValue, (newValue) => {
      if (newValue !== searchText.value) {
        searchText.value = newValue; // Update the local searchText when modelValue changes
      }
    });
    onMounted(() => {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY, // Use the environment variable
        libraries: ['places'],
      });

      loader.load()
        .then(() => {
          // eslint-disable-next-line no-undef
          const autocomplete = new google.maps.places.Autocomplete(
            autocompleteInput.value,
            props.options
          );

          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              const formatted_address = place.formatted_address || '';
              emit('placeSelected', { latitude: lat, longitude: lng, formatted_address });
              emit('update:modelValue', formatted_address);
            } else {
              console.warn('No details available for the selected place');
            }
          });
        })
        .catch(error => {
          console.error('Error loading Google Maps API:', error);
        });
    });

    return {
      autocompleteInput,
      searchText,
    };
  },
};
</script>

<style scoped>
input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}
</style>
