<template>
    <Form @submit="handleSubmit" :validation-schema="schema">
      <div class="col-md-4 ml-auto mr-auto">
        <vnud-card class="card-login card-plain">
          <template v-slot:header>
            <div>
              <div class="logo-container mb-4">
                <img src="/iev-logo.png" alt="" />
              </div>
            </div>
            <h3 class="font-weight-regular text-white text-center mb-0">
                Reset your password
            </h3>
          </template>
          <div class="login-form text-center">
            <Alert :error-bag="customError.errorBag" />
          </div>
          <div>
            <p class="text-center text-caption">Please enter your email address. We will send
                you an email to reset your password.</p>
            <vee-input name="email" type="text" inputClasses="no-border form-control-lg" placeholder="Email"
              addon-left-icon="now-ui-icons ui-1_email-85" />
  
            <div class="pt-2">
              <n-button native-type="submit" type="primary" class="mb-2" round block>
                Send Email
              </n-button>
            </div>
          </div>
          </vnud-card>
      </div>
    </Form>
  </template>
  <script setup>
  import { VeeInput } from "@/components";
  import { Form } from "vee-validate";
  import * as Yup from "yup";
  
  import { inject, ref } from "vue";
  import { apiPost, apiGet } from '@/utils/api'
  
  import { useRoute, useRouter } from 'vue-router';
  const customError = inject('customError');
  import Alert from "@/components/Alert.vue";
  
  const schema = Yup.object().shape({
    email: Yup.string().email().required().label("The Email"),
  });
  
  const route = useRoute();
  const router = useRouter();
  
  
  const handleSubmit = async (values) => {
    const forgotPasswordForm = values
    if (Form) {
      try {
        customError.resetErrorBag();
        // const response = await apiPost('/user/login', {
        //   email: forgotPasswordForm.email,
        // });
      } catch (error) {
        customError.transformValidationErrors(error.response);
      }
    }
  };
  
  
  
  </script>
  <style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  </style>