<template>
  <div class="row">
    <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
      <vnud-card class="card-user">
        <div>
          <div class="author">
            <div style="height:130px;">
              <a href="#">
                <vue-gravatar v-if="!avatar" :email="user.email" style="width:80px;height:80px" default="retro" />
                <img v-else :src="avatar" class="card-img avatar" style="width:80px;height:80px" />
                <h5 class="title">{{ user.fullname }}</h5>
              </a>
            </div>
            <div style="height:20px;">
              <n-progress v-if="!loaded" :value="loadProgress ? loadProgress : 0" :height="15" type="success" />
            </div>
            <div class="profileInfo">
              <h5>Profile Information</h5>
              <div v-if="loaded">
                <label>Email</label>
                <p class="description">
                  {{ user.email }}
                </p>
                <label>Phone</label>
                <p class="description">
                  {{ user.phone }}
                </p>
                <label>Address</label>
                <p class="description">
                  {{ user.address }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </vnud-card>
    </div>
  </div>
</template>
<script>
import { apiGet } from '@/utils/api'
import { Progress } from '@/components/index.js'
import { md5 } from 'js-md5';


export default {
  components: {
    [Progress.name]: Progress
  },
  data() {
    return {
      avatar: '',
      user: {
        fullname: '',
        phone: '',
        email: '',
      },
      loadProgress: 9,
      loaded: false,
      intervalId: null,
    }
  },
  async mounted() {
    this.intervalId = setInterval(() => this.loadProgress += 2, 100)
    this.user = await apiGet('/user')
  },
  watch: {
    'user.picture': function (to) {
      this.avatar = to
    },
    'user.email': function (to) {
      this.email = to
      this.avatar = this.avatar ? this.avatar : 'https://www.gravatar.com/avatar/' + md5(this.email)
      if (!this.loaded) {
        this.loadProgress = 100
        setTimeout(() => this.loaded = true, 1000)
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    }
  },
}
</script>
<style>
.profileInfo {
  text-align: left;
}

.profileInfo label {
  font-weight: bold;
}
</style>
