// import { createApp } from 'vue';
import fgInput from './components/Inputs/formGroupInput.vue';
import DropDown from './components/Dropdown.vue';
import VnudCard from './components/Cards/Card.vue';
import Button from './components/Button.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(app) {
    app.component('fg-input', fgInput);
    app.component('drop-down', DropDown);
    app.component('vnud-card', VnudCard);
    app.component('n-button', Button);
  }
};

export default GlobalComponents;
