<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="content-page" class="content-bg">
    <navbar :show-navbar="showMenu" class="px-4 py-2">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{ toggled: showMenu }">
          <navbar-toggle-button @click="toggleNavbar">
          </navbar-toggle-button>
        </div>
        <a class="navbar-brand" href="/">InstaEvent</a>
      </div>

      <template v-slot:navbar-menu>
        <li class="nav-item">
          <router-link to="/auth/register" class="nav-link">
            <i class="now-ui-icons tech_mobile"></i>
            Sign Up
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/auth/login" class="nav-link">
            <i class="now-ui-icons users_circle-08"></i>
            Login
          </router-link>
        </li>

      </template>

    </navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image">
        <div class="content">
          <div class="container">
            <transition-scale axis="both">
              <RouterView />
            </transition-scale>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul>
                <li>
                  <a href="/learn-more">
                    Learn More
                  </a>
                </li>
                <li>
                  <a href="/about">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/investors">
                    Investors
                  </a>
                </li>
              </ul>
            </nav>

            <div class="copyright text-white"><a class="text-white" href="/privacy-policy">Privacy Policy</a> - <a class="text-white" href="/terms-and-conditions">Terms
                and
                Services</a> -<a class="text-white" href="/copyright-policy"> &copy; {{ year }}</a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { Navbar, NavbarToggleButton } from '@/components/index.js';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    }
  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeUnmount() {
    this.closeMenu()
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
}
</script>
<style lang="scss" scoped>
$scaleSize: .8;

@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  100% {
    opacity: 1;
  }
}

.copyright a {
  font-size: 0.8571em;
  text-transform: uppercase;
  text-decoration: none;
}
.dashboard-footer .copyright a {
  color: #222 !important;
}
.beta {
  margin: 0px auto;
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
.content-bg {
  background: linear-gradient(90deg, #184e88 0, #075ec2 60%, #184e88);
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
