<template>
  <div>
    <!-- Thumbnails row -->
    <div class="d-flex flex-wrap justify-content-center blueimp-gallery-wrapper" style="min-height:100px; margin-top: 0px;">
      <div v-for="(thumb, imageIndex) in thumbs" :key="imageIndex" class="blueimp-gallery-thumb" style="min-height:100px;">
        <img :src="thumb" class="image" @click="open(imageIndex)" :style="{ width: '100px', height: '100px', padding: '1px' }" />
        <img v-if="isHost || media[imageIndex].user.email === user.email" @click="deleteImage(imageIndex)"
          style="cursor: pointer; width: 20px; vertical-align: top;" class="action-button-icon"
          src="@/assets/img/glyphicons-halflings-146-square-remove.svg" />
      </div>
    </div>

    <!-- Blueimp Gallery container -->
    <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
      <div class="slides"></div>
      <h3 class="title"></h3>
      <a class="prev">‹</a>
      <a class="next">›</a>
      <a class="close">×</a>
      <a class="play-pause"></a>
      <ol class="indicator"></ol>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

// Make jQuery globally available for blueimp-gallery
window.jQuery = $;
window.$ = $;

import { apiPost } from '@/utils/api';
import { getApiUserFromLocalStorage } from '@/utils/auth';
import moment from 'moment';
import 'blueimp-gallery/css/blueimp-gallery.min.css'
export default {
  props: ['mediaList', 'isHost'],
  data() {
    return {
      images: [],       // Array to hold the media items for gallery
      index: 1,         // Current image index
      media: [],        // Local media array
      user: getApiUserFromLocalStorage() // Current user from local storage
    };
  },
  mounted() {
    this.media = this.mediaList; // Initialize media with mediaList prop
  },
  watch: {
    mediaList: {
      handler(newList) {
        this.media = newList;
      },
      immediate: true
    }
  },
  computed: {
    thumbs() {
      return this.media.map(i => i.thumb);
    }
  },
  methods: {
    async open(index) {
      this.index = index; // Set the current index
      const list = this.media.map(media => {
        const by = media.user.fullname || media.user.email;
        const at = moment.utc(media.createdAt).format('MM/DD/YY');
        const item = { href: media.uri, title: `${by} ${at}` };
        if (media.type === 'video') item.type = 'video/mp4'; // Set video type
        return item;
      });

      // Load Blueimp Gallery dynamically
      // eslint-disable-next-line no-unused-vars
      const blueimpGallery = await import('blueimp-gallery/js/blueimp-gallery.js');
      // eslint-disable-next-line no-unused-vars
      const videoGallery = await import('blueimp-gallery/js/blueimp-gallery.js');
      // eslint-disable-next-line no-unused-vars
      const fullscreen = await import('blueimp-gallery/js/blueimp-gallery.js');

      // Ensure that the blueimp.Gallery is called correctly
      window.blueimp.Gallery(list, {
        index: this.index,
        container: '#blueimp-gallery',
        carousel: false
      });
    },
    async deleteImage(index) {
      const deleteConfirm = await this.$swal({
        title: 'Delete Media?',
        text: 'Are you sure you would like to delete this upload?',
        type: 'question',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'Yes, Delete Media!',
        cancelButtonText: 'Keep Media',
        buttonsStyling: false
      });

      if (deleteConfirm.value) {
        const mediaId = this.media[index].id;
        await apiPost(`/media/${mediaId}/delete`);
        this.media.splice(index, 1); // Remove the image from the media array
      }
    }
  }
};
</script>

<style scoped>
/* Styles for blueimp gallery controls */
.blueimp-gallery>.close {
  position: absolute;
  top: 5px;
}

.blueimp-gallery>.title {
  top: 80vh;
  position: absolute;
}

.action-button-icon {
  cursor: pointer;
  filter: invert(1);
  width: 14px;
}
.blueimp-gallery-wrapper {
  margin-top: -18px !important;
}
.blueimp-gallery-thumb {
    flex-basis: 25%;
    margin-top: 20px;
    display: flex;
}
@media(max-width: 576px) {
  .blueimp-gallery-thumb {
    flex-basis: 50%;
  }
}
</style>
