<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card card-body-classes="table-full-width" title="Saved Locations" no-footer-line>
          <template v-slot:header>
            <div>
              <h4 class="card-title" style="float: left">Saved Locations</h4>
            </div>
          </template>
          <el-table stripe style="width: 100%" :data="pagedLocations">
            <el-table-column prop="text" label="Location">
            </el-table-column>
            <el-table-column header-align="right" label="Actions">
              <template v-slot="{ row }">
                <div style="width:150px" class="text-right table-actions ml-auto">
                  <el-tooltip content="View" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="goToLocation(row.id)">
                      <i class="fa-regular fa-eye"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="editLocation(row.id)">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="deleteLocation(row.id)">
                      <i class="fa-solid fa-rectangle-xmark"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <template v-slot:footer>
            <n-pagination class="pagination-no-border" :total="locations.length" :perPage="perPage"
              v-model="locationPage" />
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="d-flex align-items-center">
                <span class="mr-2">Locations per page: </span>
                <el-select class=" per-page-selector" placeholder="Locations per page" v-model="perPage">
                  <el-option v-for="option in perPageOptions" :value="option" :label="option" :key="option">
                  </el-option>
                </el-select>
              </div>
              <el-tooltip content="New Location" :open-delay="300" placement="top">
                <n-button type="primary" round="" icon="" size="lg" style="float: right;" @click="addLocation()">
                  <i class="now-ui-icons ui-1_simple-add text-white"></i>
                </n-button>
              </el-tooltip>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPost } from '@/utils/api'
import {
  Pagination as NPagination,
  VnudCard,
} from '@/components/index.js'

export default {
  components: {
    VnudCard,
    NPagination
  },
  data() {
    return {
      locations: [],
      locationPage: 1,
      perPage: 30,
      perPageOptions: [5, 10, 30, 50]
    }
  },
  computed: {
    pagedLocations: function () {
      let start = (this.locationPage - 1) * this.perPage
      let end = start + this.perPage
      return this.locations.slice(start, end)
    }
  },
  mounted: async function () {
    this.locations = await apiGet('/location')
  },
  methods: {
    addLocation: async function () {
      this.$router.push('locations/new')
    },
    goToLocation: function (id) {
      this.$router.push('/locations/' + id)
    },
    editLocation: function (id) {
      this.$router.push('/locations/' + id + '/edit')
    },
    deleteLocation: async function (id) {
      let deleteLocation = await this.$swal({
        title: 'Delete Location?',
        text: `Are you sure you would like to delete this location?`,
        icon: 'warning',
        type: 'question',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn  btn-success btn-fill',
        confirmButtonText: 'Yes, Delete Location!',
        cancelButtonText: 'Keep Location',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (deleteLocation.value) {
        await apiPost('/location/' + id + '/delete')
        // eslint-disable-next-line no-self-assign
        window.location = window.location
      }
    }
  }
}
</script>
<style scoped>
.per-page-selector {
  width: 100px;
}
</style>
