<template>
 <div>
    <vnud-card class="stacked-form" title="Create Contact">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title">
            Upload Contacts CSV
          </h4>
          <div content="Go Back" :open-delay="300" placement="top">
            <n-button type="info" size="sm" @click="backToContact">
              <img class="action-button-icon" style="cursor:pointer"
                src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
            </n-button>
          </div>
        </div>
      </template>
      <form method="#" action="#" @submit.prevent>
          <div class="upload-csv m-0">
            <div class="form-group">
              <label for="file">Select CSV File</label>
              <input type="file" @change="handleFileUpload" accept=".csv" />
            </div>
            <n-button @click="uploadCSV" class="text-white" :disabled="!file">Upload CSV</n-button>
          </div>
        </form>
    </vnud-card>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
 </div>
 
</template>

<script>
import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import { apiPostFile } from '@/utils/api'

export default {
  components: {
    Modal,
    VnudCard,
  },
  data() {
    return {
      file: null,
      showAlert: false,
      alertMessage: '',
    };
  },
  methods: {
    backToContact() {
      this.$router.push('/contacts/')
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async uploadCSV() {
      this.alertMessage ='';
      this.showAlert = false;

      if (!this.file) {
        this.alertMessage = 'Please select a file.';
        this.showAlert = true
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      try {
        const response = await apiPostFile('/contact/upload-csv', formData);
        this.alertMessage = 'Contacts uploaded successfully!';
        this.showAlert = true
        this.file = null;  
        this.$router.push('/contacts/')
      } catch (error) {
        this.alertMessage = 'Failed to upload contacts. Please try again.';
        this.showAlert = true
      }
    },
  },
};
</script>

<style scoped>
.upload-csv {
  margin: 20px;
}
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>