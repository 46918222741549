<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart" title="Contact" no-footer-line>
          <template v-slot:header>
            <div class="card-title">
              <h4>Contact Info</h4>
              <div style="float: right; margin-top: -70px;">
                <div content="Go Back" :open-delay="300" placement="top">
                  <n-button type="info" size="sm" @click="Contacts()">
                    <img class="action-button-icon" style="cursor:pointer"
                      src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                  </n-button>
                </div>
              </div>
            </div>
          </template>
          <br>
          <div>
            <strong>Name:</strong> {{ contact.name }}
          </div><br>
          <div>
            <strong>Email:</strong> {{ contact.email }}
          </div><br>
          <div>
            <strong>Phone:</strong> {{ contact.phone }}
          </div><br>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet } from '@/utils/api'
import { VnudCard } from '@/components/index.js'
// import { METHODS } from 'http'

export default {
  components: { VnudCard },
  data() {
    return {
      contact: {
        name: '',
        email: '',
        phone: ''
      }
    }
  },
  async mounted() {
    this.contact = await apiGet('/contact/' + this.$route.params.contactId)
  },

  methods: {
    Contacts: function () {
      this.$router.push('/contacts/')
    }
  },
}

</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>
