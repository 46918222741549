<template>
  <div class="px-4 d-flex flex-column h-screen">
    <div class="bg-white login-warpper mx-auto w-100">
      <div class="login-header text-center position-relative pa-4 overflow-hidden">
        <img src="/iev-logo.png" class="mx-auto" width="58" cover />
        <h3 class="font-weight-regular">
          Reset your password
        </h3>
      </div>
      <div class="login-tab">
        <form>
          <div class="pa-4">
            <div class="login-form">
              <alert :error-bag="errorBag" />
              <v-alert v-if="hasMessage" type="success">
                <div>{{ successMsgBag['status'] }}</div>
               </v-alert>
            </div>
            <v-text-field
              prepend-inner-icon="mdi-email"
              placeholder="youremail@example.com"
              mailto:placeholder="youremail@example.com"
              hide-details="auto"
              class="login-field mb-3"
              variant="solo"
              v-model="signupForm.email"
              @blur="v$.email.$touch"
              @input="v$.email.$touch"
              :error-messages="v$.email.$errors.map((e) => e.$message)"
            ></v-text-field>

            <v-text-field
              placeholder="Your password"
              prepend-inner-icon="mdi-lock"
              variant="solo"
              hide-details="auto"
              class="login-field mb-3"
              v-model="signupForm.password"
              type="password"
              @blur="v$.password.$touch"
              @input="v$.password.$touch"
              :error-messages="v$.password.$errors.map((e) => e.$message)"
            ></v-text-field>

            <v-text-field
              placeholder="Confirm password"
              prepend-inner-icon="mdi-lock"
              variant="solo"
              hide-details="auto"
              class="login-field"
              type="password"
              v-model="signupForm.password_confirmation"
              @blur="v$.password_confirmation.$touch"
              @input="v$.password_confirmation.$touch"
              :error-messages="v$.password_confirmation.$errors.map((e) => e.$message)"
            ></v-text-field>
          </div>
          <v-btn
            height="70"
            color="primary"
            class="rounded-0 w-100 text-uppercase"
            @click="handleSubmit"
          >
            Submit
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { reactive } from 'vue';

const signupForm = reactive({
  email: '',
  password: '',
  confirm_password: ''
})
const signupFormRules = {
  email: { required, email },
  password: { required },
  password_confirmation: { required },
};
const v$ = useVuelidate(signupFormRules, signupForm);

const handleSubmit = async () => {
  const result = await v$.value.$validate();
  if (result) {
    try {
    console.log(signupForm);    
    } catch (error) {
      console.log("error" + error);
    }
  }
};
</script>
