<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart text-center cardCenter" subTitle="" description="" no-footer-line>
          <h4>Main Menu</h4>
          <div>
            <div style="float: left; margin:30px;text-align:center;">
              <img style="cursor:pointer" @click="goEvents" width="80" src="@/assets/img/001-calendar.svg" />
              <br>
              <strong>Events</strong>
            </div>


            <div style="float: left; margin:30px;text-align:center;">
              <img style="cursor:pointer" @click="goInvite" width="80" src="@/assets/img/003-invitation.svg" />
              <br>
              <strong>Invites</strong>
            </div>
          </div>
          <div style="clear:both">
            <div style="float: left; margin:30px;text-align:center;">
              <img style="cursor:pointer" @click="goLoc" width="80" src="@/assets/img/002-pin.svg" />
              <br>
              <strong>Locations</strong>
            </div>
            <div style="float: left; margin:30px;text-align:center;">
              <img style="cursor:pointer" @click="goContact" width="80" src="@/assets/img/004-friends.svg" />
              <br>
              <strong>Contacts</strong>
            </div>
          </div>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dashboard-page',
  methods: {
    goEvents() {
      this.$router.push('/events/')
    },
    goInvite() {
      this.$router.push('/invites/received/')
    },
    goLoc() {
      this.$router.push('/locations/')
    },
    goContact() {
      this.$router.push('/contacts/')
    }
  }
}
</script>
<style>
.cardCenter div {
  display: table;
  margin: 0px auto;
}
</style>
