<template>
  <div class="row">
    <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
      <vnud-card class="stacked-form" title="Edit Profile">
        <template v-slot:header>
          <h2 class="card-title">
            Edit Profile
          </h2>
        </template>
        <form method="#" action="#" @submit.prevent>
          <div class="text-center">
            <edit-profile-avatar :user="user" />
          </div>
          <br>
          <n-progress v-if="!loaded" :value="loadProgress" :height="15" type="success" />
          <hr>
          <fg-input type="text" label="Full Name" required="required" placeholder="Full Name" v-model="user.fullname">
          </fg-input>
          <fg-input type="phone" label="Mobile Phone" placeholder="Mobile Phone" v-model="user.phone">
          </fg-input>
          <fg-input label="My Address" autocomplete="off">
            <template v-slot:default>
              <AutoComplete v-model="user.address" :options="{ types: ['geocode'] }" placeholder="Enter a location"
                @placeSelected="onPlaceSelected" />
            </template>
          </fg-input>

          <n-button type="info" @click="updateProfile">Save Profile</n-button>
        </form>
      </vnud-card>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPut } from '@/utils/api'
import EditProfileAvatar from './EditProfileAvatar.vue'
import { Progress } from '@/components/index.js'
import AutoComplete from '@/components/Map/AutoComplete.vue';
export default {
  components: {
    EditProfileAvatar,
    [Progress.name]: Progress,
    AutoComplete
  },
  data() {
    return {
      locationInput: '',
      user: {
        picture: '',
        fullname: '',
        email: '',
        phone: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      loadProgress: 9,
      loaded: false,
      intervalId: null,
    }
  },
  async mounted() {
    this.intervalId = setInterval(() => this.loadProgress += 2, 100)
    this.user = await apiGet('/user')
    this.locationInput = this.user.address ? this.user.address : ''
  },
  watch: {
    'user.email': function () {
      if (!this.loaded) {
        this.loadProgress = 100
        this.loaded = true
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    }
  },
  methods: {
    async updateProfile() {
      if (!this.user.fullname) {
        this.$swal({
          title: `Name is required`,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill'
        })
      } else {
        await apiPut('/user', this.user)
        this.$router.push('/profile')
      }
    },
    onPlaceSelected(place) {
      this.locationInput = place.formatted_address
      this.user.address = place.formatted_address
      this.user.latitude = place.latitude
      this.user.longitude = place.longitude
    },
  }
}

</script>
<style></style>
