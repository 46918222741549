<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Edit Location">
          <template v-slot:header>
            <h4 class="card-title">
              Edit Location
            </h4>
          </template>
          <form method="#" action="#" @submit.prevent>
            <div>
              <fg-input v-model="text" label="Name" placeholder="Enter the event's location" />
              <n-button @click="saveLocation" class="text-white">Save Location</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <p>Please enter all required data</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import { apiPut, apiGet } from '@/utils/api'

export default {
  components: {
    Modal,
    VnudCard,
  },
  data() {
    return {
      id: '',
      text: '',
      location: {},
      showAlert: false,
    }
  },
  async mounted() {
    this.id = this.$route.params.locationId
    this.location = await apiGet('/location/' + this.id)
    this.text = this.location.text
  },
  methods: {
    saveLocation: async function () {
      if (!this.text) {
        this.showAlert = true
      } else {
        await apiPut('/location/' + this.id, { text: this.text })
        this.$router.push('/locations/' + this.id)
      }
    },
  }
}
</script>
<style></style>
